import React from 'react';
import {
  Container,
  ContactRegion,
  ContactTitle,
  ContactLink,
  ContactLinkSeparator,
  SocialRegion,
  SocialLink,
  FootnoteRegion,
  FootnoteLink,
  FootnoteLinkSeparator,
  BrandRegion,
  BrandLink
} from './style';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon
} from '../../../social-icon';
import * as links from '../../../../utilities/links';
import { contactEmail, contactPhone } from '../../../../utilities/constants';
import { Brand } from '../../../logo';

export default () => (
  <Container>
    <ContactRegion>
      <ContactTitle>Get in touch!</ContactTitle>
      <ContactLink href={`tel:${contactPhone}`}>{contactPhone}</ContactLink>
      <ContactLinkSeparator>or</ContactLinkSeparator>
      <ContactLink href={`mailto:${contactEmail}`}>{contactEmail}</ContactLink>
    </ContactRegion>
    <SocialRegion>
      <SocialLink href={links.facebook()} target="_blank">
        <FacebookIcon />
      </SocialLink>
      <SocialLink href={links.instagram()} target="_blank">
        <InstagramIcon />
      </SocialLink>
      <SocialLink href={links.linkedin()} target="_blank">
        <LinkedInIcon />
      </SocialLink>
    </SocialRegion>
    <FootnoteRegion>
      <FootnoteLink to={links.privacy()}>Privacy Policy</FootnoteLink>
      <FootnoteLinkSeparator>•</FootnoteLinkSeparator>
      <FootnoteLink to={links.terms()}>Terms & Conditions</FootnoteLink>
    </FootnoteRegion>
    <BrandRegion>
      <BrandLink to="/">
        <Brand />
      </BrandLink>
    </BrandRegion>
  </Container>
);
