import React from 'react';
import styled from '@emotion/styled/macro';
import brandUrl from './brand.svg';
import { ReactComponent as LogoSvg } from './logo.svg';

const BrandComponent = props => (
  <img alt="Bondspark" src={brandUrl} {...props} />
);

export const Brand = styled(BrandComponent)``;
export const Logo = styled(LogoSvg)`
  max-width: 100%;
`;
