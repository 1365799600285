export const LGMaxWidth = 1440;
export const MDMaxWidth = 1024;
export const SMMaxWidth = 800;
export const XSMaxWidth = 480;

export const mediaMaxWidth = width => `@media (max-width: ${width}px)`;
export const mediaMinWidth = width => `@media (min-width: ${width}px)`;

export const XS = mediaMaxWidth(XSMaxWidth);
export const SM = mediaMaxWidth(SMMaxWidth);
export const MD = mediaMaxWidth(MDMaxWidth);
export const LG = mediaMaxWidth(LGMaxWidth);
export const LGMIN = mediaMinWidth(LGMaxWidth);
export const Max900 = mediaMaxWidth(900);
