import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import { Brand } from '../../../logo';
import { Link } from 'react-router-dom';
import { Container as IconDot } from '../../../icon-dot';
import { Container as MaterialIcon } from '../../../material-icon';
import { Candy, Midnight } from '../../../../styles/colours';
import { EaseOutCirc } from '../../../../styles/easing';
import ActiveLink from '../../../active-link';

const MenuDropdownOpenAnimation = keyframes`
  from {
    opacity: 0.5;
    margin-top: -20px;
  }
  to {
    opacity: 1;
    margin-top: 1px;
  }
`;
const MenuDropdownOpen = css`
  display: block;
  animation: ${MenuDropdownOpenAnimation} 0.5s ${EaseOutCirc} forwards;
`;

export const MenuDropdown = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 1px;
  background-color: #ffffff;
  box-shadow: 0 12px 14px 0 rgba(0, 35, 61, 0.35), 0 1px 0 0 #d5dade;
`;

export const BrandContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 17px;
  margin-right: auto;

  ${Brand} {
    height: 18px;
    position: relative;
    top: 2px;
  }
`;

const MenuToggleOpen = css`
  color: ${Candy};
  ${IconDot} {
    transform: rotate(180deg);
  }
`;

export const MenuToggle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 17px;
  margin-left: auto;

  ${IconDot} {
    margin-left: 10px;
    top: 0;
    transition: all 0.6s ${EaseOutCirc};
  }
`;

export const SubMenuTitle = styled.div`
  color: #b3bfc9;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 5px 15px;
`;

export const Item = styled(ActiveLink)`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid #d5dade;
  text-decoration: none;
  color: ${Midnight};

  &.active {
    border-left: solid 5px ${Candy};
    padding-left: 10px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ItemIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

export const ItemContent = styled.div``;

export const ItemTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2px;
`;

export const ItemDescription = styled.div`
  color: #718594;
  font-size: 12px;
  line-height: 15px;
`;

export const SubMenu = styled.div`
  background-color: #f8fafc;
  border-bottom: 1px solid #d5dade;
  padding: 5px 0 0 0;

  ${Item} {
    border-bottom: none;
  }
`;

const RoundIcon = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
`;

export const TestimonialIcon = styled.div`
  ${RoundIcon};
  background-color: rgba(255, 209, 18, 0.2);
  font-size: 12px;
  line-height: 40px;

  ${MaterialIcon} {
    color: #ffd112;
  }
`;

export const LearnIcon = styled.div`
  ${RoundIcon};
  background-color: #ccf2ff;
  img {
    position: relative;
    top: 2px;
    width: 33px;
  }
`;

export const StoryIcon = styled.div`
  ${RoundIcon};
  background-color: #ffc0e4;

  svg {
    position: relative;
    top: 4px;
    width: 20px;
  }
`;

export const ContactRegion = styled.div`
  padding: 25px 25px;
`;

export const ContactTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const ContactLink = styled.a`
  display: inline-block;
  color: #718594;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

export const ContactLinkSpacer = styled.div`
  display: inline-block;
  padding: 0 10px;
  color: #b3bfc9;
`;

const ContainerOpen = css`
  ${MenuDropdown} {
    ${MenuDropdownOpen};
  }

  ${MenuToggle} {
    ${MenuToggleOpen};
  }
`;

export const Container = styled.div`
  height: 44px;
  box-shadow: 0 1px 0 0 #e2e8ed;
  background-color: #ffffff;
  display: flex;
  position: relative;
  z-index: 1000;

  ${({ open }) => open && ContainerOpen};
`;
