import styled from '@emotion/styled';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';

const Icon = styled.svg`
  width: 16px;
  height: 16px;
`;

export const FacebookIcon = Icon.withComponent(Facebook);
export const InstagramIcon = Icon.withComponent(Instagram);
export const LinkedInIcon = Icon.withComponent(LinkedIn);
