import React from 'react';
import { Container } from './style';

const MaterialIcon = ({ icon, ...props }) => (
  <Container {...props}>
    <i className="material-icons">{icon}</i>
  </Container>
);

export const KeyboardArrowDown = props => (
  <MaterialIcon icon="keyboard_arrow_down" {...props} />
);

export const KeyboardArrowLeft = props => (
  <MaterialIcon icon="keyboard_arrow_left" {...props} />
);

export const KeyboardArrowRight = props => (
  <MaterialIcon icon="keyboard_arrow_right" {...props} />
);

export const ArrowForward = props => (
  <MaterialIcon icon="arrow_forward" {...props} />
);

export const ArrowDownward = props => (
  <MaterialIcon icon="arrow_downward" {...props} />
);

export const Add = props => <MaterialIcon icon="add" {...props} />;
export const Loop = props => <MaterialIcon icon="loop" {...props} />;
export const Check = props => <MaterialIcon icon="check" {...props} />;
export const Star = props => <MaterialIcon icon="star" {...props} />;
export const Info = props => <MaterialIcon icon="info_outline" {...props} />;
export const Mouse = props => <MaterialIcon icon="mouse" {...props} />;
export const Cafe = props => <MaterialIcon icon="local_cafe" {...props} />;
export const Voice = props => (
  <MaterialIcon icon="record_voice_over" {...props} />
);
export const Document = props => <MaterialIcon icon="description" {...props} />;
export const Account = props => (
  <MaterialIcon icon="account_balance" {...props} />
);

export { Container };
export default MaterialIcon;
