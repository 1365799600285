import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Candy } from '../../styles/colours';
import { ReactComponent as blue } from './blue.svg';
import { ReactComponent as yellow } from './yellow.svg';

const Icon = css`
  margin-bottom: -4px;
  max-width: 100%;
  max-height: 100%;
`;

export const Blue = styled(blue)`
  ${Icon}
`;

export const Yellow = styled(yellow)`
  ${Icon}
`;

export const CandyCalculator = styled(yellow)`
  ${Icon}

  path, polygon, #Rectangle-Copy-16, #Rectangle {
    fill: ${Candy} !important;
  }
`;
