import React, { useState, useCallback } from 'react';
import {
  Container,
  BrandContainer,
  MenuToggle,
  MenuDropdown,
  SubMenu,
  SubMenuTitle,
  Item,
  ItemIcon,
  ItemContent,
  ItemTitle,
  ItemDescription,
  TestimonialIcon,
  LearnIcon,
  StoryIcon,
  ContactRegion,
  ContactTitle,
  ContactLink,
  ContactLinkSpacer
} from './style';
import { Brand, Logo } from '../../../logo';
import IconDot from '../../../icon-dot';
import { KeyboardArrowDown } from '../../../material-icon';
import { Blue, Yellow } from '../../../calculator-icon';
import { Star } from '../../../material-icon';
import BrainIcon from '../../../brain-icon';
import * as links from '../../../../utilities/links';
import { contactPhone, contactEmail } from '../../../../utilities/constants';

export default () => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    setOpen(open => !open);
  }, [setOpen]);
  return (
    <Container open={open}>
      <BrandContainer to="/">
        <Brand />
      </BrandContainer>
      <MenuToggle onClick={onToggle}>
        Menu
        <IconDot>
          <KeyboardArrowDown />
        </IconDot>
      </MenuToggle>
      <MenuDropdown>
        <SubMenu>
          <SubMenuTitle>Calculators</SubMenuTitle>
          <Item to={links.affordability()}>
            <ItemIcon>
              <Blue />
            </ItemIcon>
            <ItemContent>
              <ItemTitle>Affordability Calculator</ItemTitle>
              <ItemDescription>How much can I afford?</ItemDescription>
            </ItemContent>
          </Item>
          <Item to={links.repayment()}>
            <ItemIcon>
              <Yellow />
            </ItemIcon>
            <ItemContent>
              <ItemTitle>Repayment Calculator</ItemTitle>
              <ItemDescription>What is my monthly repayment?</ItemDescription>
            </ItemContent>
          </Item>
        </SubMenu>
        <Item to={links.testimonials()}>
          <ItemIcon>
            <TestimonialIcon>
              <Star />
              <Star />
              <Star />
            </TestimonialIcon>
          </ItemIcon>
          <ItemContent>
            <ItemTitle>Testimonials</ItemTitle>
            <ItemDescription>
              What do people say about Bondspark?
            </ItemDescription>
          </ItemContent>
        </Item>
        <Item to={links.learn()}>
          <ItemIcon>
            <LearnIcon>
              <BrainIcon />
            </LearnIcon>
          </ItemIcon>
          <ItemContent>
            <ItemTitle>Learn</ItemTitle>
            <ItemDescription>Become a master at home loans</ItemDescription>
          </ItemContent>
        </Item>
        <Item to={links.webinars()}>
          <ItemIcon>
            <LearnIcon>
              <BrainIcon />
            </LearnIcon>
          </ItemIcon>
          <ItemContent>
            <ItemTitle>Webinars</ItemTitle>
            <ItemDescription>Become a master at home loans</ItemDescription>
          </ItemContent>
        </Item>
        <Item to={links.story()}>
          <ItemIcon>
            <StoryIcon>
              <Logo />
            </StoryIcon>
          </ItemIcon>
          <ItemContent>
            <ItemTitle>Our Story</ItemTitle>
            <ItemDescription>Who is bondspark?</ItemDescription>
          </ItemContent>
        </Item>
        <ContactRegion>
          <ContactTitle>Want to get in touch?</ContactTitle>
          <ContactLink href={`tel:${contactPhone}`}>{contactPhone}</ContactLink>
          <ContactLinkSpacer>•</ContactLinkSpacer>
          <ContactLink href={`mailto:${contactEmail}`}>
            {contactEmail}
          </ContactLink>
        </ContactRegion>
      </MenuDropdown>
    </Container>
  );
};
