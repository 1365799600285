import React from 'react';
import Desktop from './components/desktop';
import Mobile from './components/mobile';
import { VisibleOnMDandLarger, VisibleOnSMandSmaller } from '../responsive';

export default ({ animate }) => (
  <>
    <VisibleOnSMandSmaller>
      <Mobile />
    </VisibleOnSMandSmaller>
    <VisibleOnMDandLarger>
      <Desktop animate={animate} />
    </VisibleOnMDandLarger>
  </>
);
