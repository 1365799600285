import styled from '@emotion/styled/macro';

export const Container = styled.div`
  display: inline-block;
  color: inherit;

  .material-icons {
    color: inherit;
    display: inline-block;
    font-size: 1em;
    top: 0.15em;
    line-height: 1;
    position: relative;
  }
`;
