import styled from '@emotion/styled';
import ActiveLink from '../../../active-link';
import { Midnight, Candy } from '../../../../styles/colours';
import { mediaMaxWidth } from '../../../../styles/responsiveness';

const smBreakpoint = mediaMaxWidth(900);

export const Container = styled(ActiveLink)`
  display: inline-block;
  color: ${Midnight};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  padding-top: 20px;
  margin: 0 16px;

  &:hover {
    color: ${Candy};
  }

  &.active {
    color: ${Candy};
    padding-top: 15px;
    font-weight: 600;
  }

  ${smBreakpoint} {
    margin: 0 8px;
  }
`;
