import React from 'react';
import { Container, NavContainer, BrandContainer, NavSection } from './style';
import { Brand } from '../../../logo';
import NavItem from '../nav-item';
import CalculatorsDropdown from '../calculators-dropdown';
import GetStartedDropdown from '../get-started-dropdown';
import * as links from '../../../../utilities/links';

export default ({ animate = false }) => (
  <Container animate={animate}>
    <NavContainer>
      <BrandContainer to="/">
        <Brand />
      </BrandContainer>
      <NavSection>
        <CalculatorsDropdown />
        <NavItem to={links.testimonials()}>Testimonials</NavItem>
        <NavItem to={links.learn()}>Learn</NavItem>
        <NavItem to={links.webinars()}>Webinars</NavItem>
        <NavItem to={links.story()}>Our Story</NavItem>
        <GetStartedDropdown />
      </NavSection>
    </NavContainer>
  </Container>
);
