export const maxAllowedRepaymentPercentageOfGross = 30;
export const primeInterestRate = 11.75;
export const incomeInitialValue = 0;
export const incomeMinValue = 10000;
export const incomeMaxValue = 1000000;
export const deductionsInitialValue = 0;
export const deductionsMinValue = 0;
export const deductionsMaxValue = 1000000;
export const expensesInitialValue = 0;
export const expensesMinValue = 0;
export const expensesMaxValue = 1000000;
export const termInitialValue = 20;
export const rateInitialValue = primeInterestRate;
export const rateMin = primeInterestRate - 3;
export const rateMax = primeInterestRate + 4;
export const rateStep = 0.05;
export const priceInitialValue = 1200000;
export const priceMinValue = 300000;
export const priceMaxValue = 300000000;
export const depositInitialValue = null;
export const depositMinValue = 0;
export const depositMaxValue = 300000000;

export const BANKS = {
  ABSA: 'absa',
  FNB: 'fnb',
  INVESTEC: 'investec',
  NEDBANK: 'nedbank',
  RMB: 'rmb',
  STANDARDBANK: 'standardBank'
};
export const defaultSelectedBanks = {
  [BANKS.ABSA]: true,
  [BANKS.FNB]: true,
  [BANKS.INVESTEC]: false,
  [BANKS.NEDBANK]: true,
  [BANKS.RMB]: false,
  [BANKS.STANDARDBANK]: true
};

export const contactPhone = '010 880 3820';
export const contactEmail = 'hello@bondspark.co.za';

export const rateDistributionResolution = 0.25;
// Retrieved on 2020-08-05 from https://app.powerbi.com/groups/f1d74d57-20be-454f-8687-cd6bb41f0fa7/reports/0dce9185-f3c1-41fe-8f62-932cb4f95e02/ReportSection5263e864a5a46750db1d
export const rateDistribution = [
  {
    relativeRate: -2,
    total: 1
  },
  {
    relativeRate: -1.75,
    total: 7
  },
  {
    relativeRate: -1.5,
    total: 8
  },
  {
    relativeRate: -1.25,
    total: 16
  },
  {
    relativeRate: -1,
    total: 43
  },
  {
    relativeRate: -0.75,
    total: 54
  },
  {
    relativeRate: -0.5,
    total: 66
  },
  {
    relativeRate: -0.25,
    total: 81
  },
  {
    relativeRate: 0,
    total: 67
  },
  {
    relativeRate: 0.25,
    total: 73
  },
  {
    relativeRate: 0.5,
    total: 62
  },
  {
    relativeRate: 0.75,
    total: 49
  },
  {
    relativeRate: 1,
    total: 49
  },
  {
    relativeRate: 1.25,
    total: 27
  },
  {
    relativeRate: 1.5,
    total: 16
  },
  {
    relativeRate: 1.75,
    total: 13
  },
  {
    relativeRate: 2,
    total: 12
  },
  {
    relativeRate: 2.25,
    total: 9
  },
  {
    relativeRate: 2.5,
    total: 3
  },
  {
    relativeRate: 2.75,
    total: 1
  },
  {
    relativeRate: 4.25,
    total: 1
  }
];
rateDistribution.forEach(x => (x.rate = primeInterestRate + x.relativeRate));
