import React from 'react';
import {
  Container,
  Dropdown,
  LabelContainer,
  LabelText,
  Item,
  Wrapper,
  DropdownTriangle
} from './style';
import DropdownArrow from '../dropdown-arrow';

const Label = ({ children, to }) => (
  <LabelContainer to={to}>
    <LabelText>{children}</LabelText>
    <DropdownArrow />
  </LabelContainer>
);

const Menu = ({ children }) => (
  <Wrapper>
    <Dropdown>
      <DropdownTriangle />
      {children}
    </Dropdown>
  </Wrapper>
);

export default {
  Container,
  Menu,
  Label,
  Item
};
