import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Midnight } from '../../styles/colours';

export const LabelText = styled.div`
  display: inline-block;
`;

export const LabelContainer = styled(Link)`
  &,
  &:visited,
  &:link,
  &:active {
    color: ${Midnight};
  }
`;

export const Wrapper = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 15px;
  margin-right: -15px;
`;

export const Dropdown = styled.div`
  border: 1px solid #eaecee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #d5dade;
  position: relative;
`;

export const DropdownTriangle = styled.div`
  &::before {
    top: -8px;
    right: 14px;
    position: absolute;
    content: ' ';
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: #eaecee transparent;
  }

  &::after {
    top: -6.5px;
    right: 14px;
    position: absolute;
    content: ' ';
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: #fff transparent;
    z-index: 1;
  }
`;

export const Item = styled.div`
  position: relative;
  z-index: 5;
`;

export const Container = styled.div`
  position: relative;

  &:hover {
    ${Wrapper} {
      display: block;
    }
  }
`;
