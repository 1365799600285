import styled from '@emotion/styled';
import { U2, Mountain, Midnight } from '../../../../styles/colours';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: ${U2};
  padding-top: 15px;
  /* margin-top: 60px; */
`;

export const Row = styled.div`
  margin: 0 -25px;
  display: flex;
`;

export const Section = styled.div`
  padding: 25px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 12px;
`;

export const SectionLink = styled(Link)`
  color: ${Mountain};
  font-size: 12px;
  line-height: 1;
  padding: 5px 0;
  display: block;
`;

export const FootnoteBar = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 30px 0;
`;

export const BrandRegion = styled(Link)`
  display: block;

  img {
    height: 15px;
  }
`;

export const FootnoteRegion = styled.div`
  margin: 0 auto;
  color: ${Mountain};
  font-size: 12px;
  line-height: 16px;
`;

export const FootnoteSpacer = styled.div`
  display: inline-block;
  padding: 0 8px;
`;

export const FootnoteLink = styled(Link)`
  color: inherit;
`;

export const FootnoteLinkExternal = styled.a`
  color: inherit;
`;

export const FootnoteLabel = styled.div`
  display: inline-block;
  color: ${Midnight};
`;

export const SocialRegion = styled.div``;

export const SocialLink = styled.a`
  display: inline-block;
  padding: 10px;
`;

export const ApplyButtonSpacer = styled.div`
  height: 5px;
`;

export const IntroVideoBanner = styled.img`
  max-width: 167px;
`;
