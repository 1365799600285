import styled from '@emotion/styled';
import NavItem from '../nav-item';
import ActiveLink from '../../../active-link';
import { Candy, Midnight, Mountain, U2 } from '../../../../styles/colours';

const ContainerElement = styled(NavItem.withComponent('div'))`
  padding-top: 19px;
`;

export const Container = styled(ContainerElement)`
  position: relative;
`;

export const Item = styled(ActiveLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 14px 17px;

  @media (hover: hover) {
    &:hover {
      background: ${U2};
    }
  }
`;

export const Title = styled.div`
  color: ${Midnight};
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;

  span {
    color: ${Candy};
  }
`;

export const Description = styled.div`
  color: ${Mountain};
  font-size: 12px;
  white-space: nowrap;
`;

export const Icon = styled.div`
  min-width: 40px;
`;

export const Details = styled.div`
  padding: 0 5px 0 16px;
`;

export const ActiveBar = styled.div`
  background: ${Candy};
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
