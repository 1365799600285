import styled from '@emotion/styled/macro';
import { Container as MaterialIcon } from '../material-icon';
import { Midnight, U1 } from '../../styles/colours';

export const Container = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  color: ${Midnight};
  background: ${U1};
  font-size: 15px;
  position: relative;
  top: 2px;

  ${MaterialIcon} {
    position: relative;
    top: -1.5px;
  }
`;
