import React from 'react';
import { Container, Item, Title, Description, Icon, Details } from './style';
import Dropdown from '../../../dropdown';
import { Blue, Yellow } from '../../../calculator-icon';
import { Route } from 'react-router-dom';
import * as links from '../../../../utilities/links';

export default () => (
  <Container>
    <Route path="/calculators" />
    <Dropdown.Container>
      <Dropdown.Label to={links.calculators()}>Calculators</Dropdown.Label>
      <Dropdown.Menu>
        <Dropdown.Item>
          <Item to={links.affordability()}>
            <Icon>
              <Blue />
            </Icon>
            <Details>
              <Title>Affordability Calculator</Title>
              <Description>How much can I afford?</Description>
            </Details>
          </Item>
        </Dropdown.Item>
        <Dropdown.Item>
          <Item to={links.repayment()}>
            <Icon>
              <Yellow />
            </Icon>
            <Details>
              <Title>Repayment Calculator</Title>
              <Description>What is my monthly repayment?</Description>
            </Details>
          </Item>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Container>
  </Container>
);
