import styled from '@emotion/styled';
import { SMMaxWidth } from '../../styles/responsiveness';

export const VisibleOnSMandSmaller = styled.div`
  @media all and (min-width: ${SMMaxWidth + 1}px) {
    display: none;
  }
`;

export const VisibleOnMDandLarger = styled.div`
  @media all and (max-width: ${SMMaxWidth}px) {
    display: none;
  }
`;
