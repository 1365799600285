import styled from '@emotion/styled/macro';
import { keyframes, css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Brand } from '../../../logo';

const ContainerTopFadeInAnimation = keyframes`
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0px);
  }
`;

const ContainerAnimate = css`
  transform: translateY(-50px);
  animation: ${ContainerTopFadeInAnimation} 2.2s cubic-bezier(0.36, 0, 0, 1.01)
    forwards;
  animation-delay: 0.4s;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1000;
  ${({ animate }) => animate && ContainerAnimate}
`;

export const NavContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BrandContainer = styled(Link)`
  display: inline-block;
  /* padding: 18px 0; */

  ${Brand} {
    height: 22px;
  }
`;

export const NavSection = styled.nav`
  float: right;
`;
