import styled from '@emotion/styled/macro';
import { U1, Midnight, Candy } from '../../styles/colours';
import { Container as MaterialIcon } from '../material-icon';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  white-space: nowrap;
  @media (hover: hover) {
    &:hover {
      transform: translateY(-2px);
      transition: all 0.1s ease-out;
    }
  }
`;

export const DefaultButton = styled(Button)`
  display: inline-block;
  text-decoration: none;
  box-shadow: 0 2px 0 0 ${U1};
  border: 1px solid ${U1};
  background-color: #ffffff;
  color: ${Midnight};
  font-size: 16px;
  line-height: 1;
  padding: 8px 35px 9px 30px;
  border-radius: 18px;
  position: relative;

  ${MaterialIcon} {
    position: absolute;
    right: 5px;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
  }
`;

export const DefaultButtonExternal = DefaultButton.withComponent('a');

export const DefaultInvertedButton = styled(DefaultButton)`
  color: #fff;
  background: ${Midnight};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  border-color: ${Midnight};

  @media (hover: hover) {
    &:hover {
      color: #fff;
    }
  }
`;

export const CandyButton = styled(DefaultButton)`
  color: ${Candy};
`;

export const CandyInvertedButton = styled(DefaultButton)`
  background: ${Candy};
  border: 1px solid ${Candy};
  color: #fff;
  box-shadow: 0 12px 14px 0 rgba(255, 30, 126, 0.24);

  svg {
    fill: #fff;
  }

  @media (hover: hover) {
    &:hover {
      color: #fff;
      box-shadow: 0 12px 25px 0 rgba(255, 30, 126, 0.24);
    }
  }
`;

export const CandyInvertedButtonExternal = CandyInvertedButton.withComponent(
  'a'
);

export const CandyRoundIconButton = styled(Button)`
  display: block;
  background: ${Candy};
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.2);
  padding: 0;
  color: #fff;
  font-size: 32px;
  line-height: 1.5em;
  text-align: center;

  @media (hover: hover) {
    &:hover {
      color: #fff;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    }
  }
`;
