import React from 'react';
import Dropdown from '../../../dropdown';
import * as links from '../../../../utilities/links';
import { DefaultButtonExternal } from '../../../button';
import { Container, Item, Title, Description, Details } from './style';

export default () => (
  <Container>
    <Dropdown.Container>
      <DefaultButtonExternal to={null}>Get Started</DefaultButtonExternal>
      {/* <Dropdown.Label to={''}>Get Started</Dropdown.Label> */}
      <Dropdown.Menu>
        <Dropdown.Item>
          <Item to={links.learn()}>
            <Details>
              <Title>
                <span>Learn</span> about home loans
              </Title>
              <Description>All the jargon simplified</Description>
            </Details>
          </Item>
        </Dropdown.Item>
        <Dropdown.Item>
          <Item to={links.prequalify()}>
            <Details>
              <Title>
                <span>Prequalify</span> for a home loan
              </Title>
              <Description>Know your buying power</Description>
            </Details>
          </Item>
        </Dropdown.Item>
        <Dropdown.Item>
          <Item to={links.apply()}>
            <Details>
              <Title>
                <span>Apply</span> now to 7 institutions
              </Title>
              <Description>Get your dream home loan</Description>
            </Details>
          </Item>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Container>
  </Container>
);
