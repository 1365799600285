import React from 'react';
import Desktop from './components/desktop';
import Mobile from './components/mobile';
import { VisibleOnMDandLarger, VisibleOnSMandSmaller } from '../responsive';

export default () => (
  <>
    <VisibleOnMDandLarger>
      <Desktop />
    </VisibleOnMDandLarger>
    <VisibleOnSMandSmaller>
      <Mobile />
    </VisibleOnSMandSmaller>
  </>
);
