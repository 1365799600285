import React from 'react';
import CenterContainer from '../../../center-container';
import {
  Container,
  Row,
  Section,
  Title,
  SectionLink,
  FootnoteBar,
  BrandRegion,
  FootnoteRegion,
  FootnoteSpacer,
  FootnoteLink,
  FootnoteLinkExternal,
  SocialRegion,
  FootnoteLabel,
  ApplyButtonSpacer,
  SocialLink,
  IntroVideoBanner
} from './style';
import { CandyButton } from '../../../button';
import { ArrowForward } from '../../../material-icon';
import { Brand } from '../../../logo';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon
} from '../../../social-icon';
import * as links from '../../../../utilities/links';
import { contactEmail, contactPhone } from '../../../../utilities/constants';
import ThumbUrl from './placeholder_left.png';
import { Link } from 'react-router-dom';

export default () => (
  <Container>
    <CenterContainer>
      <Row>
        <Section>
          <Link to={links.story()}>
            <IntroVideoBanner
              src={ThumbUrl}
              alt="Footer image of fingers walking across the bottom of the page"
            />
          </Link>
        </Section>
        <Section>
          <Title>Still looking for a property?</Title>
          <SectionLink to={links.affordability()}>
            What can I afford?
          </SectionLink>
          <SectionLink to={links.repayment()}>
            What will my monthly repayments be?
          </SectionLink>
          <SectionLink to={links.testimonials()}>
            How much can I save with Bondspark?
          </SectionLink>
          <SectionLink to={links.learn()}>
            Become a master at home loans
          </SectionLink>
        </Section>
        <Section>
          <Title>Get to know us</Title>
          <SectionLink to={links.story()}>Our Story</SectionLink>
          <SectionLink to={links.testimonials()}>Testimonials</SectionLink>
          <SectionLink to={links.learn()}>
            Frequently asked home loan questions
          </SectionLink>
        </Section>
        <Section>
          <Title>Ready to apply?</Title>
          <ApplyButtonSpacer />
          <CandyButton to={links.apply()}>
            Apply online now!
            <ArrowForward />
          </CandyButton>
        </Section>
      </Row>
      <FootnoteBar>
        <BrandRegion to="/">
          <Brand />
        </BrandRegion>
        <FootnoteRegion>
          © {new Date().getFullYear()} Bondspark, Ltd.
          <FootnoteSpacer>•</FootnoteSpacer>
          <FootnoteLink to={links.privacy()}>Privacy Policy</FootnoteLink>
          <FootnoteSpacer>•</FootnoteSpacer>
          <FootnoteLink to={links.terms()}>Terms &amp; Conditions</FootnoteLink>
          <FootnoteSpacer>•</FootnoteSpacer>
          <FootnoteLabel>Get in touch:&nbsp;</FootnoteLabel>
          <FootnoteLinkExternal href={`mailto:${contactEmail}`}>
            {contactEmail}
          </FootnoteLinkExternal>
          <FootnoteSpacer>•</FootnoteSpacer>
          <FootnoteLinkExternal href={`tel:${contactPhone}`}>
            {contactPhone}
          </FootnoteLinkExternal>
        </FootnoteRegion>
        <SocialRegion>
          <SocialLink target="_blank" href={links.facebook()}>
            <FacebookIcon />
          </SocialLink>
          <SocialLink target="_blank" href={links.instagram()}>
            <InstagramIcon />
          </SocialLink>
          <SocialLink target="_blank" href={links.linkedin()}>
            <LinkedInIcon />
          </SocialLink>
        </SocialRegion>
      </FootnoteBar>
    </CenterContainer>
  </Container>
);
