import React from 'react';
import { Container } from './style';
import { KeyboardArrowDown } from '../material-icon';

export default () => (
  <Container>
    <KeyboardArrowDown />
  </Container>
);

export { Container };
