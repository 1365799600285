import styled from '@emotion/styled';
import TiledShapesContainer from '../../../tiled-shapes-container';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: #f8fafc;
`;

export const ContactRegion = styled(TiledShapesContainer)`
  padding: 30px 0;
  font-size: 16px;
  line-height: 20px;
  text-shadow: 0 0 6px 0 #f2f3f4;
  display: flex;
  justify-content: center;
  background-size: 30%;

  > * {
    padding: 5px 15px;
  }

  @media (max-width: 550px) {
    display: block;
    text-align: center;
  }
`;

export const ContactTitle = styled.div``;

export const ContactLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-weight: bold;
`;

export const ContactLinkSeparator = styled.div``;

export const SocialRegion = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const SocialLink = styled.a`
  padding: 7px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FootnoteRegion = styled.div`
  display: flex;
  justify-content: center;
  color: #718594;
  font-size: 12px;
  line-height: 16px;
`;

export const FootnoteLink = styled(Link)`
  color: inherit;
`;

export const FootnoteLinkSeparator = styled.div`
  padding: 0 8px;
`;

export const BrandRegion = styled.div`
  text-align: center;
  padding-top: 10px;
`;

export const BrandLink = styled(Link)`
  display: inline-block;
  padding: 15px;
  color: inherit;

  img {
    height: 18px;
  }
`;
